/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { Helmet } from 'react-helmet';

const ComeDineWithMeUnsolved = () => {
   return (
      <div>
         <div style={{ backgroundColor: '#f7fcfc', padding: '20px', borderBottom: '1px solid lightgray' }}>
            <Helmet>
               <title>Unsolved come dine with me cases</title>
            </Helmet>
            <h2>"lady gaga’s just dance"</h2>
            <p>
               I can’t find this video anywhere. But me and my mam always used to have a good laugh watching this woman on come dine with me
               dance in her kitchen to lady gaga’s just dance. Does anybody know this episode? She’s an older woman, skinny, a short blonde
               bob if I remember. I can’t remember who she was dining with so sorry that’s all the info I can give. Any help would be
               appreciated
            </p>
            <p>
               I’ve been wracking my head all day to try and think and I just don’t remember. I don’t remember any of the other contestants
               either😅my mam seems to think it was somewhere down south from us (we live in Cumbria, the Lake District) but that doesn’t
               really narrow it down😅
            </p>
            <p>
               Thank you, but no it’s not that one. It’s not a lady gaga themed night in general. She’s just in her kitchen and she’s
               drinking wine while she’s cooking and decides to put on lady gaga and dance to the camera ahaha. She’s quite skinny, short
               bobbed blonde hair, in maybe her 40’s
            </p>
            <h4>Lady gaga (but not in theme), bob, blonde, 40's, south of Cumbria </h4>
            <div style={{ marginBottom: '20px' }}></div>
            <h2>"GOAT ate the menu!"</h2>
            <p>
               So my mum for ages has been in hysterics everytime she think back to this one episode A farmer was reading the menu and a
               goat came up and ate it I’ve scoured the internet and can’t find it could someone help please 🙏🏼{' '}
            </p>
         </div>
         <div style={{ margin: '20px' }}></div>
      </div>
   );
};

export default ComeDineWithMeUnsolved;
